/*
 * @Author: liyu
 * @Date: 2022-06-13 11:42:32
 * @LastEditTime: 2022-07-12 11:21:39
 * @Description: file content
 * @FilePath: /sensors-www/src/components/IterativeComponent/Tab/index.tsx
 */
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useScroll } from 'ahooks'
import { Link } from 'gatsby'
import { isBrowser } from 'utils/utils'
import * as styles from './index.module.less'

interface TabItemProps {
  url: string
  name: string
  isActive?: boolean
}
interface TabProps {
  id?: string
  className?: string
  linkSpanClassName?: string
  // textColor: string
  // shadow?: string
  list?: TabItemProps[]
}
/**
 *
 * @param id 容器ID
 * @param props className 容器类名
 * @param props linkSpanClassName Link 下 span 类名
 * @param props bgColor pc端激活背景色，css 类名
 * @param props textColor 移动端激活时文字的颜色，css 类名
 * @param props shadow 阴影色，css 类名
 * @param props list TabItemProps[] 列表
 * @param props list[url] 跳转链接
 * @param props list[name] tab名
 * @param props list[isActive] boolean 是否激活
 * @returns
 */
const Tab: React.FC<TabProps> = (props) => {
  const { id, list, className, linkSpanClassName } = props
  const position = useScroll(null)
  const [scrollY, setScorllY] = useState<number>(0)

  useEffect(() => {
    setScorllY(position?.top || 0)
  }, [position?.top])

  useEffect(() => {
    if (isBrowser()) {
      const { state } = window.history
      if (state?.scrollY) {
        window.scrollTo(0, state?.scrollY)
      }
    }
  }, [])
  return (
    <nav id={id} className={classnames('px-[2rem] ', className)}>
      <ul className={classnames('max-w-[1200px]  flex m-auto bg-[#F7F9FA]')}>
        {list?.map((item) => (
          <li className={classnames('flex-1 relative')} key={item.url}>
            <Link
              className={classnames('block   text-center text-[1.4rem] lg:text-[20px]', styles.TabItem)}
              to={item.url}
              state={{ scrollY }}
            >
              <span
                className={classnames('hidden  lg:leading-[60px] lg:inline-block px-[24px]', linkSpanClassName, {
                  ['border-b-4 border-[#04CB94] text-[#04CB94]']: item.isActive,
                })}
              >
                {item.name}
              </span>
              <span
                className={classnames('block  bg-[#F7F9FA] leading-[4rem] lg:hidden', {
                  ['text-[#04CB94]']: item.isActive,
                })}
              >
                {item.name}
              </span>
            </Link>
            <span
              className={classnames(
                'block lg:hidden w-[3rem]  h-[0.3rem] absolute bottom-0 left-1/2 translate-x-[-50%]',
                {
                  ['bg-[#04CB94]']: item.isActive,
                },
              )}
            ></span>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Tab
