/*
 * @Author: liyu
 * @Date: 2022-05-18 15:17:58
 * @LastEditTime: 2022-05-19 10:21:57
 * @Description: file content
 * @FilePath: /sensors-www/src/components/PartnerCard/index.tsx
 */
import React from 'react'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'

import classnames from 'classnames'
import * as styles from './index.module.less'

interface PartnerCardProps {
  className?: string
  dataSource: {
    imgUrl: any
  }[]
}
const PartnerCard: React.FC<PartnerCardProps> = ({ dataSource, className }) => {
  return (
    <div className={classnames(styles.PartnerCardContainer, className)}>
      <ul className="clients-card">
        {dataSource?.map((item, index) => (
          <li key={index}>
            <img src={item?.imgUrl?.publicURL || item?.imgUrl} alt="" />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PartnerCard
