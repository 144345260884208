/*
 * @Author: liyu
 * @Date: 2022-05-16 10:29:19
 * @LastEditTime: 2022-06-17 21:48:48
 * @Description: file content
 * @FilePath: /sensors-www/src/components/IterativeComponent/Panorama/index.tsx
 */
import * as React from 'react'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface PanoramaProps {
  btnText?: string
  btnCode?: string
  imgUrl?: string
  className?: string
  viewPhotos?: string
  imgBottomText?: string
  imgStylePc?: React.CSSProperties
  altTitle?: string
  btnTextTwo?: string
  btnTextTwoUrl?: string
}

const Panorama: React.FC<PanoramaProps> = ({
  imgBottomText = '微信扫码 即刻咨询',
  btnText,
  btnCode,
  imgUrl,
  className,
  imgStylePc,
  viewPhotos = '点击图片查看大图',
  altTitle,
  btnTextTwo,
  btnTextTwoUrl,
}) => {
  return (
    <div className={classnames(styles.panoramaContainer, className)}>
      <div className="panorama-img">
        <img src={imgUrl} alt={altTitle} style={imgStylePc} />
      </div>
      <div className="panorama-img-mb">
        <a href={imgUrl} target="_blank">
          <img src={imgUrl} alt={altTitle} />
        </a>
      </div>
      <div className="panorama-img-text">{viewPhotos}</div>
      {btnText && (
        <div className="panorama-btn">
          <ScanCodeConsultButton buttonText={btnText} qrcode={btnCode} imgBottomText={imgBottomText} />
          <a className="linkBtn" target="_blank" href={btnTextTwoUrl}>
            <span className="textBtn">{btnTextTwo}</span>
          </a>
        </div>
      )}
    </div>
  )
}

export default Panorama
