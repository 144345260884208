import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'

interface ConsultProps {
  title?: string
  desc?: string
  dataList?: {
    iconImg: string
    iconText: string
  }[]
  btnText?: string
  btnCode?: string
  className?: string
}

const Consult: React.FC<ConsultProps> = ({ title, desc, dataList, btnText, btnCode, className }) => {
  return (
    <div className={classnames(styles.ConsultContainer, className)}>
    <div className={classnames(styles.ConsultContent,'max-w-[1200px] text-center pt-8 px-8 pb-[5.6rem] lg:pt-[60px] lg:px-0 lg:pb-[95px]')}>
      {title && (<div className="text-center text-[2.2rem] font-normal leading-[3.6rem] lg:text-[32px] lg:leading-[60px]">{title}</div>)}
      {desc && (<div className="text-center text-[2.2rem] font-normal leading-[3.6rem] lg:text-[32px] lg:leading-[60px]">{desc}</div>)}
      <div className="text-center mt-[1.7rem] lg:mt-[87px] flex flex-col lg:flex-row lg:flex-wrap justify-center items-center">
        {dataList?.map((item) => (
          <>
            <div key={item.iconText} className={classnames(styles.listContent,'basis-[32.33%] mt-12 lg:mt-0')}>
                <img src={item?.iconImg}></img>
                <div className="text-[1.8rem] leading-[3rem] mt-[2rem] lg:text-[24px] lg:font-medium lg:leading-[33px]">{item.iconText}</div>
            </div>
            <div className={classnames(styles.listBorder,'h-0 w-24 mt-[2.9rem] lg:mt-0 lg:w-0 lg:h-[72px] border border-dashed border-white')}></div> 
          </>   
        ))}
        </div>
        {btnText && (
          <div className="text-center mt-16 lg:mt-[107px]">
            <ScanCodeConsultButton
                buttonText={btnText}
                qrcode={btnCode}
            />
          </div>
        )}
      </div>
    </div>
  )
  }

export default Consult
  