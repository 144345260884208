import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classnames from 'classnames'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Tab from 'components/IterativeComponent/Tab'
import Banner from 'components/Banner'
import Button, { ButtonType } from 'components/Base/Button'
import SectionSubstance, { SectionSubstanceBody } from 'components/IterativeComponent/SectionSubstance'
import MousewheelSwiper from 'components/IterativeComponent/MousewheelSwiper'
import ProductCard from 'components/IterativeComponent/ProductCard'
import PartnerCard from 'components/PartnerCard'
import CardList from 'components/IterativeComponent/CardList'
import CargoCard from 'components/IterativeComponent/CargoCard'
import Panorama from 'components/IterativeComponent/Panorama'
import Consult from 'components/IterativeComponent/Consult'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import SectionSwiperMobile from 'components/IterativeComponent/SectionSwiperMobile'
import ArticleFooter from 'components/IterativeComponent/ArticleFooter'
import { Languages } from 'i18n/config'

import * as styles from './index.module.less'
import { useSetState } from 'ahooks'

const Entertain: React.FC = (props) => {
  const { entertainYaml } = useStaticQuery(graphql`
    query {
      entertainYaml {
        title
        description
        keywords
        saTitle
        banner_title
        banner_desc
        banner_btnHref
        banner_btnText
        banner_title_m
        banner_bg_mb
        banner_bg_pc
        banner_icon
        banner_icon_mb
        tabs {
          isActive
          name
          url
        }
        section_two_operationTitle
        section_two_operationTitleMb
        section_two_operationDesc
        section_two_mobileButtonText
        section_two_mobileButtonLink
        section_two_dataList {
          buttonHref
          buttonText
          dataTitle
          descList
          imgUrl
        }
        section_three_title
        section_three_desc
        section_three_cardList {
          cover
          desc
          title
        }
        panorama_title
        panorama_titleMb
        panorama_imgUrl
        panorama_desc
        panorama_btnText
        panorama_btnCode
        panorama_btnText_two
        panorama_btnText_two_url
        partner_buttonText
        partner_dataList {
          imgUrl
        }
        partner_qrcode
        product_dataList {
          btnHref
          btnText
          dataDsc
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        product_title
        product_titleMb
        digitization_btnHref
        digitization_btnText
        digitization_desc
        digitization_orderHref
        digitization_orderText
        digitization_title
        cargo_dataList {
          btnHref
          btnText
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        cargo_title
        cargo_titleMb
        customer_desc
        customer_title
        customer_titleMb
        consult_title
        consult_desc
        consult_dataList {
          iconImg
          iconText
        }
        consult_btnText
        consult_btnCode
      }
    }
  `)
  return (
    <Layout {...props}>
      <EntertainState data={entertainYaml} />
    </Layout>
  )
}

interface EntertainStateProps {
  lang?: Languages
  data?: {
    section_three_cardList: Array<{ title: string; desc: string; cover: string }>
    [propName: string]: any
  }
}
export const EntertainState: React.FC<EntertainStateProps> = (props) => {
  const { data } = props

  return (
    <>
      <Seo title={data?.title} description={data?.description} keywords={data?.keywords} saTitle={data?.saTitle} />
      <main className={classnames('pt-[4rem] lg:pt-[64px] relative', styles.Entertain)}>
        <Banner
          style={{
            background: `url(${data?.banner_bg_pc?.publicURL || data?.banner_bg_pc}) no-repeat`,
            backgroundSize: 'cover',
            color: '#fff',
          }}
          bannerIconClassName={styles.BannerIcon}
          className={styles.Banner}
          descClassName="!max-w-[490px]"
          titleMbClassName="!text-[3rem]"
          bannerIconMbClassName="!w-[354px] !h-[231px]"
          bannerIcon={data?.banner_icon?.publicURL || data?.banner_icon}
          bannerImgMb={data?.banner_bg_mb?.publicURL || data?.banner_bg_mb}
          title={data?.banner_title}
          desc={data?.banner_desc}
          titleMb={data?.banner_title_m}
          btnText={data?.banner_btnText}
          btnHref={data?.banner_btnHref}
          bannerIconMb={data?.banner_icon_mb?.publicURL || data?.banner_icon_mb}
        />
        <Tab id="tabs" className="bg-[#F7F9FA]" linkSpanClassName="lg:!leading-[53px]" list={data?.tabs} />
        {/* 轮播 */}
        <SectionSubstance
          style={{
            paddingBottom: 0,
          }}
          className={classnames('relative z-10 !pt-[4rem] lg:!pt-[120px]')}
          title={data?.section_two_operationTitle}
          titleMb={data?.section_two_operationTitleMb}
          descText={data?.section_two_operationDesc}
        >
          <MousewheelSwiper
            className="hidden lg:block"
            dataSource={data?.section_two_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
            }))}
          />
          <SectionSwiperMobile
            className="block lg:hidden mt-[2rem]"
            dataSource={data?.section_two_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
            }))}
            slotButton={
              <Button btnType="primary" href={data?.section_two_mobileButtonLink} target="_blank">
                {data?.section_two_mobileButtonText}
              </Button>
            }
          />
        </SectionSubstance>
        {/* 为什么选择神策 */}
        <SectionSubstance
          title={data?.section_three_title}
          titleMb={data?.section_three_title}
          descText={data?.section_three_desc}
          className="!pb-0 lg:!pb-[90px]"
        >
          <SectionSubstanceBody className="pt-0 lg:pt-[60px]">
            <CardList
              isHeight={false}
              cardClassName={styles.Card}
              titleColor="#1F2D3D"
              yGap={20}
              xGap={33}
              mYGap={0}
              mXGap={0}
              datas={data?.section_three_cardList}
              iconClassName="h-[10rem] lg:h-[94.5px]"
              descClassName="w-[298px] !text-[14px]"
            />
          </SectionSubstanceBody>
        </SectionSubstance>
        {/* 策略咨询 */}
        <SectionSubstance style={{ padding: 0 }} className={styles.Consult}>
          <Consult
            title={data?.consult_title}
            desc={data?.consult_desc}
            dataList={data?.consult_dataList?.map((item) => ({
              ...item,
              iconImg: item?.iconImg?.publicURL || item?.iconImg,
            }))}
            btnText={data?.consult_btnText}
            btnCode={data?.consult_btnCode?.publicURL || data?.consult_btnCode}
          />
        </SectionSubstance>
        {/* 全景图 */}
        <SectionSubstance title={data?.panorama_title} titleMb={data?.panorama_title} descText={data?.panorama_desc}>
          <SectionSubstanceBody>
            <Panorama
              className="panorama-container"
              btnText={data?.panorama_btnText}
              btnCode={data?.panorama_btnCode?.publicURL || data?.panorama_btnCode}
              imgUrl={data?.panorama_imgUrl?.publicURL || data?.panorama_imgUrl}
              btnTextTwo={data?.panorama_btnText_two}
              btnTextTwoUrl={data?.panorama_btnText_two_url}
            />
          </SectionSubstanceBody>
        </SectionSubstance>
        {/* logo */}
        <SectionSubstance
          className={classnames(styles.logoWrap, '!pt-[2rem] !pb-0 lg:!pt-[40px] lg:!pb-[100px]')}
          title={data?.customer_title}
          titleMb={data?.customer_titleMb}
          descText={data?.customer_desc}
        >
          {/* PC间距，移动端间距在PartnerCard上已有 */}
          <div className="hidden lg:block lg:h-[40px]" />
          {/* logo列表 */}
          <PartnerCard dataSource={data?.partner_dataList} />
          {/* 扫描二维码 */}
          <div className="text-center mt-[20px] lg:mt-[40px]">
            <ScanCodeConsultButton
              buttonText={data?.partner_buttonText}
              buttonType={ButtonType.Primary}
              qrcode={data?.partner_qrcode?.publicURL || data?.partner_qrcode}
            />
          </div>
        </SectionSubstance>
        {/* 更多干货 */}
        <SectionSubstance title={data?.cargo_title} titleMb={data?.cargo_titleMb} className="lg:!pt-0">
          <div className="h-[2rem] lg:h-[40px]">{/* 间距 */}</div>
          <CargoCard
            dataSource={data?.cargo_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
              imgUrlMb: item?.imgUrlMb?.publicURL || item?.imgUrlMb,
            }))}
          />
        </SectionSubstance>
        {/* 神策产品 */}
        <SectionSubstance className="!pt-0" title={data?.product_title} titleMb={data?.product_titleMb}>
          <div className="h-[2rem] lg:h-[40px]">{/* 间距 */}</div>
          <ProductCard
            className={styles.Product}
            dataSource={data?.product_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
              imgUrlMb: item?.imgUrlMb?.publicURL || item?.imgUrlMb,
            }))}
          />
        </SectionSubstance>
        {/* 尾部 */}
        <ArticleFooter
          className={styles.Bottom}
          title={data?.digitization_title}
          gameDsc={data?.digitization_desc}
          btnText={data?.digitization_btnText}
          btnHref={data?.digitization_btnHref}
          orderText={data?.digitization_orderText}
          orderHref={data?.digitization_orderHref}
        />
      </main>
    </>
  )
}

export default Entertain
